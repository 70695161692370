import cloneDeep from "lodash.clonedeep";
import _isEqual from "lodash.isequal";

import store from "../../store";

import { trackPageView } from "./tagManager";

import { createCookie, getCookie } from "./cookieHelper";
import { querySearch, removeParam } from "./api/hashQueryParameters";
import { isIOSdevice } from "./browserUtil";
import {
  convertUTCDateToLocalDate,
  getDisplayDate,
  DISPLAY_DATE,
  getTimeDifferenceFromCurrentInSeconds,
} from "./dateService";
import {
  SINGLE_DAY,
  MULTIPLE_DAYS,
  SINGLE_HOUR,
  MULTIPLE_HOURS,
  SINGLE_MINUTE,
  MULTIPLE_MINUTE,
  FEW_SECONDS,
} from "../constants/Text";

import {
  ALLOWED_PATH_NOT_LOGGED_IN_USER,
  ALLOWED_COMMON_PATHS,
  ALLOWED_PATH_LOGGED_IN_USER,
  CONTACT_NUMBER_INTERNATIONAL_CODE,
  CONTACT_NUMBER_MARKETWISE_PLACEHOLDERS,
  DISABLE_MARKET_CHANGE,
  HTML_LINK_TAG,
  HTML_META_TAG,
  PAGE_SIZE,
  PRICE_UNIT,
  SCROLL_ON_CHANGE_TIMEOUT_IN_MILLISECONDS,
  SCROLL_TIMEOUT_IN_MILLISECONDS,
  USER_TYPE,
} from "../constants/configuration";
import { PLURAL_STRINGS } from "../constants/JSON";

// #region Object Service

/**
 * create a new copy of object
 * @param {object} original - original object
 * @return {any} object
 */
export function cloneObject(original) {
  return cloneDeep(original);
}

/**
 * Check if object already exists in array or not
 * @param {array} list array of objects
 * @param {any} obj object
 * @return {boolean} if present returns true else false
 */
export function isSameObjectPresent(list, obj) {
  for (const item of list) {
    if (_isEqual(item, obj)) return true;
  }
  return false;
}

/**
 * checks if nested object keys are present
 * @param {object} obj - object
 * @return {bool} if present returns true else false
 */
export function isNestedObjectKeyPresent(
  obj /* , level1, level2, ... levelN */,
) {
  const args = Array.prototype.slice.call(arguments, 1);

  for (let i = 0; i < args.length; i++) {
    if (!obj || !Object.prototype.hasOwnProperty.call(obj, args[i])) {
      return false;
    }
    obj = obj[args[i]];
  }
  return true;
}

/**
 * return random value from array
 * @param {any} array - array of any
 * @retrun {any}
 */
export function getRandomValueFromArray(array) {
  return array[Math.floor(Math.random() * array.length)];
}

// Returns if a value is an object
export function isObject(value) {
  return value && typeof value === "object" && value.constructor === Object;
}

export function sortAlphabetically(data, key) {
  data.sort((value1, value2) => {
    const currentValue = value1[key].toLowerCase();
    const nextValue = value2[key].toLowerCase();
    return currentValue !== nextValue ? (currentValue < nextValue ? -1 : 1) : 0;
  });
  return data;
}

// #endregion

// #region Enum related Functions
export function getKeyNameFromEnumValue(data, value) {
  return data && Object.keys(data).find((item) => data[item] === value);
}
// #endregion

// #region Converting Cases Services (Pascal|Camel)
/**
 *convert camel case string to pascal case
 * isSuccess -> IsSuccess
 * @param {string} data - string data
 * @return {string} output
 */
export function camelCaseToPascalCase(data) {
  return data.charAt(0).toUpperCase() + data.slice(1);
}

/**
 * convert pascal case string to camel case
 * IsSuccess -> isSuccess
 * @param {string} data - string data
 * @return {any} output
 */
export function pascalCaseToCamelCase(data) {
  return data.charAt(0).toLowerCase() + data.slice(1);
}

/**
 * convert data into objectCase
 * @param {object} data - data
 * @param {string} caseType - camel/pascal
 * @return {any} output
 */
function contvertObjectToGivenCase(data, caseType) {
  let newObject;
  let originalKey;
  let newKey;
  let value;
  if (data instanceof Array) {
    return data.map(function (value) {
      if (typeof value === "object") {
        value = mapObjectKeysToCamelCase(value);
      }
      return value;
    });
  }
  newObject = {};
  for (originalKey in data) {
    if (Object.prototype.hasOwnProperty.call(data, originalKey)) {
      if (caseType === "camel")
        newKey = pascalCaseToCamelCase(originalKey).toString();
      else newKey = camelCaseToPascalCase(originalKey).toString();
      value = data[originalKey];
      if (value instanceof Array || (value && value.constructor === Object)) {
        value = mapObjectKeysToCamelCase(value);
      }
      newObject[newKey] = value;
    }
  }

  return newObject;
}

/**
 * Convert object keys to camel case
 * @param {object} data - data
 * @return {any} object
 */
export function mapObjectKeysToCamelCase(data) {
  return contvertObjectToGivenCase(data, "camel");
}

/**
 * Convert object keys to pascal case
 * @param {object} data
 * @return {any} object
 */
export function mapObjectKeysToPascalCase(data) {
  return contvertObjectToGivenCase(data, "pascal");
}

export function convertToSentenceCase(data = "") {
  return data.charAt(0).toUpperCase() + data.slice(1).toLowerCase();
}

/**
 * Formatted string if entire string is lowerCase or UpperCase
 * @param {string} data - data string
 * @retruns {string} - First Letter in capital rest in lowerCase
 */
export function convertToProperCase(data = "") {
  let updatedData = data;
  if (data && (isUpperCase(data) || isLowerCase(data))) {
    const noOfWords = data.split(" ");
    updatedData = noOfWords
      .map((subName) => {
        if (subName) return convertToSentenceCase(subName);
      })
      .join(" ");
  }
  return updatedData;
}

export function upperOrLowerToSentenceCase(data = "") {
  let updatedString = data;
  if (data && (isUpperCase(data) || isLowerCase(data)))
    updatedString = convertToSentenceCase(updatedString);
  return updatedString;
}

/**
 *
 * @param {string} - string
 * @returns {string} - removed extra spaces from string (start, in between, end)
 */
export function removeExtraSpaces(str = "") {
  let newString = str;
  if (str) {
    newString = str.replace(/\s+/g, " ").trim();
  }
  return newString;
}
// #endregion

// #region ClipBoard Services

function fallbackCopyTextToClipboard(text) {
  const textArea = document.createElement("textarea");
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus({
    preventScroll: true,
  });
  textArea.select();

  try {
    document.execCommand("copy");
  } catch (err) {
    /* eslint no-console: "off" */
    console.error("Could not copy text: ", err);
  }

  document.body.removeChild(textArea);
}

export function fallbackPasteText(el, pastedText) {
  const cursorPosStart = el.selectionStart;
  const cursorPosEnd = el.selectionEnd;
  const v = el.value;
  const textBefore = v.substring(0, cursorPosStart);
  const textAfter = v.substring(cursorPosEnd, v.length);
  const mergedText = textBefore + pastedText + textAfter;
  el.value = mergedText;
  setTimeout(() => {
    const newCursorPors = (textBefore + pastedText).length;
    el.setSelectionRange(newCursorPors, newCursorPors);
  }, 0);
}

/**
 * Copy data to clipboard
 * @param {string} data - data
 * @return {any} object
 */
export function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }

  navigator.clipboard
    .writeText(text)
    .then(() => {
      /* eslint no-console: "off" */
      console.log("Text copied to clipboard");
    })
    .catch((err) => {
      // This can happen if the user denies clipboard permissions:
      console.error("Could not copy text: ", err);
      fallbackCopyTextToClipboard(text);
    });
}
// #endregion

// #region Username/EmailId Services
/**
 * Gets domain from email address
 * @param {string} username - data
 * @return {any} object
 */
export function getDomain(username) {
  if (username) return `@${username.split("@").pop()}`;
  return "";
}
// #endregion

// #region Path/Routes Services

/**
 * validate if location is valid unauth location or not
 * @param {string} location - string
 * @return {number} if present returns true else false
 */
export function isValidUnAuthPath(location) {
  return (
    ALLOWED_PATH_NOT_LOGGED_IN_USER.indexOf(`/${location.split("/")[1]}`) > -1
  );
}

/**
 * validate if location is valid common location
 * @param {string} location - string
 * @return {number} if present returns true else false
 */
export function isValidCommonPath(location) {
  return ALLOWED_COMMON_PATHS.indexOf(`/${location.split("/")[1]}`) > -1;
}

/**
 * Returns true if passed location is valid and contains params.
 * @param {any} location
 */
export function doesParamExist(
  location,
  allowedLocation = ALLOWED_PATH_LOGGED_IN_USER,
) {
  const pattern = /\/(.*?)\//;
  let pathname = pattern.exec(location);

  if (pathname !== null) {
    pathname = pathname[1];
    const locationArray = location.split(pathname);
    if (
      allowedLocation.indexOf(`/${pathname}`) > -1 &&
      locationArray[1] &&
      locationArray[1] !== "/"
    )
      return true;
  }

  return false;
}
// #endregion

// #region Literal Services
export function getLiteraValue(route, lKey, replace) {
  const currentStoreState = store.getState();
  const literals = currentStoreState.literals.data;
  const { application } = currentStoreState.app;
  let literalValue = "";
  const literalObj = literals[route] && literals[route][lKey];
  if (literalObj) {
    if (typeof literalObj === "string") {
      literalValue = literalObj;
    } else {
      // The literal has different values for application
      literalValue = literalObj[application];
    }
    // Replace dynamic strings in the text
    if (replace) {
      replace.forEach((keyVal) => {
        // Replace keys in resource files MUST be enclosed within square brackets
        const replaceKey = `[${keyVal.key}]`;
        literalValue = literalValue.replace(replaceKey, keyVal.value);
      });
    }
  }
  return literalValue;
}

export function getLiteral(route, lKey, replace) {
  return getLiteraValue(route, lKey, replace);
}
// #endregion

// #region Document Title Service

export function setDocumentTitle(
  titleKey,
  route,
  replace,
  gtmTrackPage = true,
) {
  const storeData = store.getState().app;
  let title = getLiteral("constants", "BusinessNameDocumentTitle");

  if (
    storeData.isMyUser ||
    (!storeData.isAuthenticated && storeData.isPublicPage)
  ) {
    title = getLiteral("constants", "MusafirNameDocumentTitle");
  }

  const pageName = route ? getLiteral(route, titleKey, replace) : titleKey;
  title = (pageName && `${pageName} - `) + title;

  document.title = title;
  gtmTrackPage && trackPageView();
}

/**
 * removes html tags inside <head>
 * @param {object} tagObject - contains properties and its attributes
 * @param {string} attrProperty - property name to fetch tags
 */
function removeHtmlChildElements(tagObject, attrProperty) {
  const head =
    document.getElementsByTagName("head") &&
    document.getElementsByTagName("head")[0];

  if (head) {
    for (const property in tagObject) {
      const toBeRemovedElements = document.querySelectorAll(
        `[${attrProperty}='${property}']`,
      );
      for (let index = 0; index < toBeRemovedElements.length; index++) {
        head.removeChild(toBeRemovedElements[index]);
      }
    }
  }
}

/**
 *
 * @param {string} tagname - html tag name
 * @param {object} tagObject - contains properties and its attributes
 * @param {string} theme - business or musafir
 */
function addHtmlChildElements(tagname, tagObject, theme) {
  const head =
    document.getElementsByTagName("head") &&
    document.getElementsByTagName("head")[0];

  switch (tagname) {
    case "link":
      for (const property in tagObject) {
        tagObject[property][theme].forEach(function (attrValues) {
          const link = document.createElement("link");
          for (const attrName in attrValues) {
            link.rel = property;
            link.setAttribute(attrName, attrValues[attrName]);
          }
          head.appendChild(link);
        });
      }

      break;
    case "meta":
      for (const nameAttr in tagObject) {
        const meta = document.createElement("meta");
        meta.name = nameAttr;
        meta.content = tagObject[nameAttr][theme].content;
        head.appendChild(meta);
      }
      break;
    default:
      break;
  }
}
/**
 *
 * @param {string} theme - "business" or "musafir"
 */
export function changeThemeAndFavicon(theme = USER_TYPE.BUSINESS) {
  // remove existing meta & link tags for theme
  removeHtmlChildElements(HTML_META_TAG, "name");
  removeHtmlChildElements(HTML_LINK_TAG, "rel");

  addHtmlChildElements("link", HTML_LINK_TAG, theme);
  addHtmlChildElements("meta", HTML_META_TAG, theme);
}
// #endregion

// #region Market Related Services

export function handleMarketPreferenceChange(location, history) {
  const marketPreference = querySearch(location.search.toLowerCase()).m;
  if (marketPreference) {
    history.replace(location.pathname + removeParam("m", location.search));
    changeMarketPreference(marketPreference);
  }
}

export function changeMarketPreference(marketPreference) {
  if (marketPreference) {
    if (getCookie("MarketPreference") !== marketPreference) {
      createCookie("MarketPreference", marketPreference);
    }
    window.location.reload();
  }
}

export function getCurrentMarket() {
  return store.getState().app.marketProfileID;
}

export const getMarketWiseCurrency = () => {
  const marketProfileId = getCurrentMarket();
  return typeof PRICE_UNIT[marketProfileId] !== "undefined"
    ? PRICE_UNIT[marketProfileId]
    : "";
};

export function getContactNumberInternationalCode(
  marketProfileID,
  appendPlus = true,
) {
  return typeof CONTACT_NUMBER_INTERNATIONAL_CODE[marketProfileID] !==
    "undefined"
    ? (appendPlus ? "+" : "") +
        CONTACT_NUMBER_INTERNATIONAL_CODE[marketProfileID]
    : "";
}

export function getContactNumberPlaceholders(marketProfileID) {
  return typeof CONTACT_NUMBER_MARKETWISE_PLACEHOLDERS[marketProfileID] !==
    "undefined"
    ? CONTACT_NUMBER_MARKETWISE_PLACEHOLDERS[marketProfileID]
    : "";
}

export function getGtmId() {
  const currentStoreState = store.getState();
  return currentStoreState.app.gtmId;
}

export function getFormattedCurrency(currencyValue) {
  const cultureState = store.getState().app.culture;
  const {
    currencyGroupSeparator,
    currencyDecimalSeparator,
    currencyGroupSizes,
    currencyDecimalDigits,
  } = cultureState.numberFormat;
  return formatNumber(
    currencyValue,
    currencyGroupSeparator,
    currencyDecimalSeparator,
    currencyGroupSizes,
    currencyDecimalDigits,
  );
}

export function isMarketChangeEnabledForRoute(pathname) {
  return DISABLE_MARKET_CHANGE.indexOf(`/${pathname.split("/")[1]}`) <= -1;
}
// #endregion

// #region String Manipulations

// Returns if a value is a string
export function isString(value) {
  return typeof value === "string" || value instanceof String;
}

export const checkIfUndefinedAndAssignDefaultValue = (
  value,
  defaultValue = "",
) => {
  return typeof value !== "undefined" ? value : defaultValue;
};

export const string_chop = (str, chunkLen) => {
  const choppedString = [];

  if (!chunkLen) choppedString.push(str);
  else {
    for (
      let count = 0, addedStringLength = 0;
      count < chunkLen.length;
      count++, addedStringLength += chunkLen[count - 1]
    )
      choppedString.push(str.substr(addedStringLength, chunkLen[count]));
  }
  return choppedString;
};

export const isUpperCase = (str) => {
  return str && str === str.toUpperCase();
};

export const isLowerCase = (str) => {
  return str && str === str.toLowerCase();
};

export const removeHTMLElement = (str = "") => {
  return str.replace(/(<([^>]+)>)/gi, "");
};

/**
 * return formatted string - (Eg-replace {0} -> with second argument)
 * @param {string} format
 * @param {...any} replacingStrings
 */

export const formatString = (format, ...replacingStrings) => {
  let formattedString = format;
  for (const str in replacingStrings) {
    formattedString = formattedString.replace(
      `{${str}}`,
      replacingStrings[str],
    );
  }
  return formattedString;
};

/**
 * returns trim value
 * @param {any} value - value
 * @return {any} trim value
 */
export function getTrimValue(value) {
  return typeof value === "string" ? value.trim() : value;
}

export function clearSelectionText() {
  window.getSelection().removeAllRanges();
}

export function getPluralizeString(
  count,
  singularValue,
  pluralKey,
  isSingularAndPluralSame = false,
) {
  const pluralValue = PLURAL_STRINGS[pluralKey] || `${singularValue}s`;
  return count === 1 || isSingularAndPluralSame ? singularValue : pluralValue;
}

// #endregion

// #region Filter/Search/Check/Match Services

export function getMatchingValues(
  inputValue,
  list,
  keyName,
  pageSize = PAGE_SIZE,
) {
  const matchingValueList = [];
  for (let item = 0; item < list.length; item++) {
    if (
      list[item][keyName].toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    )
      matchingValueList.push({ ...list[item] });
    if (matchingValueList.length >= pageSize) break;
  }
  return matchingValueList;
}

export function checkDomain(domain) {
  return window.location.hostname.indexOf(domain) > -1;
}

// #endregion

// #region Number Manipulations

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/PluralRules
// https://www.codegrepper.com/code-examples/javascript/1st+2nd+3rd+function+js
export function getNumberOrdinal(number) {
  const englishOrdinalRules = new Intl.PluralRules("en", {
    type: "ordinal",
  });
  const suffixes = {
    one: "st",
    two: "nd",
    few: "rd",
    other: "th",
  };
  const suffix = suffixes[englishOrdinalRules.select(number)];
  return number + suffix;
}

export function isNumber(value) {
  return (
    value !== null &&
    (typeof value === "string" || typeof (value === "number")) &&
    !isNaN(value)
  );
}

function formatNumber(
  input,
  groupSeparator,
  decimalSeparator,
  groupSizes,
  decimalDigits,
) {
  if (!input) return input;

  // Initialize variables if undefined
  const cultureState = store.getState().app.culture;
  if (cultureState) {
    typeof groupSeparator === "undefined" &&
      (groupSeparator = cultureState.numberFormat.numberGroupSeparator);
    typeof decimalSeparator === "undefined" &&
      (decimalSeparator = cultureState.numberFormat.numberDecimalSeparator);
    typeof groupSizes === "undefined" &&
      (groupSizes = cultureState.numberFormat.numberGroupSizes);
    typeof decimalDigits === "undefined" &&
      (groupSizes = cultureState.numberFormat.numberDecimalDigits);
  }

  let inputValue = `${Math.abs(input)}`;
  inputValue = inputValue.split(decimalSeparator);

  // Compute formatted decimal value
  const decimalResult =
    inputValue.length > 1
      ? decimalSeparator +
        (decimalDigits ? inputValue[1].slice(0, decimalDigits) : inputValue[1])
      : "";

  if (input < 0)
    return `-${
      getCommaSeperatedNumber(
        inputValue[0],
        0,
        groupSizes[0],
        groupSizes,
        groupSeparator,
      ) + decimalResult
    }`;
  return (
    getCommaSeperatedNumber(
      inputValue[0],
      0,
      groupSizes[0],
      groupSizes,
      groupSeparator,
    ) + decimalResult
  );
}

function getCommaSeperatedNumber(
  input,
  iteration,
  splitSize,
  groupSizes,
  groupSeparator,
) {
  const inputLength = input.length;
  if (inputLength <= splitSize) return input;

  const subString = input.slice(0, inputLength - splitSize);
  iteration++;
  const newSplitSize = groupSizes[iteration]
    ? groupSizes[iteration]
    : splitSize;
  const result = getCommaSeperatedNumber(
    subString,
    iteration,
    newSplitSize,
    groupSizes,
    groupSeparator,
  );
  return input.replace(subString, result + groupSeparator);
}

export function gerPercentage(value, totalValue) {
  return (value / totalValue) * 100;
}

export function applyTax(value, taxAmount) {
  return Math.round(value + value * taxAmount);
}
// #endregion

// #region getting custom stack

/**
 * returns custom stack
 * @param {string} msg - custom error message
 * @return {any} stack message
 */
export function getStack(msg) {
  try {
    throw new Error(msg);
  } catch (e) {
    return e.stack;
  }
}
// #endregion

// #region functions related to scrolling and display

export function isElementInView(el) {
  const rect = el.getBoundingClientRect();
  const elemTop = rect.top;
  const elemBottom = rect.bottom;
  // Only completely visible elements return true:
  const isVisible = elemTop >= 0 && elemBottom <= window.innerHeight;
  // Partially visible elements return true:
  // isVisible = elemTop < window.innerHeight && elemBottom >= 0;
  return isVisible;
}

export function isElementBetweenHeaderAndSubmitButton(element) {
  const rect = element.getBoundingClientRect();
  const elemTop = rect.top;
  const elemBottom = rect.bottom;

  let submitButton = {};
  document.querySelectorAll("button[type='submit']").forEach((element) => {
    if (
      element.offsetTop === window.innerHeight - element.offsetHeight &&
      element.offsetWidth === window.innerWidth - element.offsetLeft
    )
      submitButton = element;
  });

  let header = {};
  document.querySelectorAll("header").forEach((element) => {
    if (
      element.offsetTop === 0 &&
      element.offsetWidth === window.innerWidth - element.offsetLeft
    )
      header = element;
  });

  const submitButtonHeight = submitButton.clientHeight || 0;
  const headerHeight = header.clientHeight || 0;
  const isVisible =
    elemTop >= headerHeight &&
    elemBottom <= window.innerHeight - submitButtonHeight;

  return isVisible;
}

export function scrollBetweenHeaderAndSubmitButton(targetElement) {
  if (!isIOSdevice()) {
    if (!isElementBetweenHeaderAndSubmitButton(targetElement)) {
      setTimeout(function () {
        targetElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }, SCROLL_ON_CHANGE_TIMEOUT_IN_MILLISECONDS);
    }
  }
}

export function getWindowSize() {
  return store.getState().app.windowSize;
}

export function scrollIntoView(targetElement) {
  if (!isIOSdevice()) {
    setTimeout(function () {
      targetElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }, SCROLL_TIMEOUT_IN_MILLISECONDS);
  }
}

export const getCreatedTimeDifference = (createdDate) => {
  const created = convertUTCDateToLocalDate(createdDate);
  let time = getTimeDifferenceFromCurrentInSeconds(created);
  time /= 60;
  const minutes = Math.floor(time % 60);
  time /= 60;
  const hours = Math.floor(time % 24);
  const days = Math.floor(time / 24);
  const year = created.getFullYear();

  if (days > 0 && !isNaN(days) && days) {
    if (days === 1) return SINGLE_DAY;
    if (days > 1 && days <= 30) return formatString(MULTIPLE_DAYS, days);
    if (days > 30) {
      if (year === new Date().getFullYear()) {
        return getDisplayDate(created, DISPLAY_DATE.DAY_MONTH);
      }
      return getDisplayDate(created);
    }
  } else if (hours > 0 && !isNaN(hours) && hours)
    return hours === 1 ? SINGLE_HOUR : formatString(MULTIPLE_HOURS, hours);
  else if (minutes > 0 && !isNaN(minutes) && minutes)
    return minutes === 1
      ? SINGLE_MINUTE
      : formatString(MULTIPLE_MINUTE, minutes);
  else return FEW_SECONDS;
};

// #endregion
