/* eslint no-undef: "off" */

import { isNestedObjectKeyPresent } from "./commonService";

export function getConfiguration(configName) {
  if (
    typeof app !== "undefined" &&
    isNestedObjectKeyPresent(app, "configuration", configName)
  )
    return app.configuration[configName];
  return null;
}

export function getNestedConfiguration(configName,childConfigName) {
  if (
    typeof app !== "undefined" &&
    isNestedObjectKeyPresent(app, "configuration", configName,childConfigName)
  )
    return app.configuration[configName][childConfigName];
  return null;
}
