import { BROWSER } from "../constants/configuration";

export function isIOSdevice() {
  return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
}

// Detect browser
export function detectBrowser(browser) {
  switch (browser) {
    case BROWSER.EDGE:
      return navigator.userAgent.toLowerCase().indexOf("edg") > -1;
    default:
      break;
  }
}

export function resetFocusOnSearchIfRedirectedDueToSearch() {
  const { activeElement } = document;
  if (activeElement.id === "search") {
    const temp_value = activeElement.value;
    activeElement.value = "";
    activeElement.value = temp_value;
  }
}
