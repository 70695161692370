import { DEFAULT_USER_TYPE } from "../constants/Text";
import { getConfiguration } from "./configurationUtil";

/**
 * Create a new cookie or update an existing one with new value
 * @param  {String} name  cookie name
 * @param  {String} value cookie value
 * @return {null}
 */
export function createCookie(name, value) {
  let expires = "";
  let domain = "";
  const days = 30;
  const date = new Date();

  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);

  // adding domain to the cookie
  try {
    domain = `; domain=${getConfiguration("cookieDomain")}`;
  } catch (err) {
    domain = "";
  }
  expires = `; expires=${date.toGMTString()}`;
  document.cookie = `${name}=${value}${domain}${expires}; path=/`;
}

export function getCookie(name) {
  const nameEquation = `${name}=`;
  const ca = document.cookie.split(";");

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1, c.length);
    }
    if (c.indexOf(nameEquation) == 0) {
      // If it is userType
      if (c.indexOf("userType") !== -1) {
        if (!c.substring(nameEquation.length, c.length)) {
          return DEFAULT_USER_TYPE;
        }
      }
      return c.substring(nameEquation.length, c.length);
    }
  }
  // At this point, there is no value stored for the cookie. Return B2C as default for userType
  if (name == "userType") {
    return DEFAULT_USER_TYPE;
  }
  return "";
}

export function deleteCookie(name) {
  createCookie(name, "", -1);
}
