export function retry(fn, retriesLeft = 5, interval = 1000) {
  return new Promise((resolve, reject) => {
    fn()
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1 || (error && error.status == 0)) {
            //   reject('maximum retries exceeded');
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}
